import React from 'react'
import { Link, graphql, navigate } from 'gatsby'

export default function Home({ data }) {
  // const posts = data.allMdx.edges
  //   .filter(e => ["post", null].includes(e.node.frontmatter.templateKey))
  //   .map(e => e.node)

  // navigate(`/feed`)
  typeof window !== 'undefined' && navigate(`/home`) // build is in Node, where window == undefined

  return null
}

// export const query = graphql`
//   query {
//     allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
//       totalCount
//       edges {
//         node {
//           id
//           frontmatter {
//             title
//             date(formatString: "DD MMMM, YYYY")
//             templateKey
//           }
//           fields {
//             slug
//           }
//           excerpt
//         }
//       }
//     }
//   }
// `
